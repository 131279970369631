require("lightbox2/dist/js/lightbox")

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "" ]

  initialize() {
    $('#allThumbs').find('.galleryImage').each((index, element) => {
      (new Image()).src = $(element).data('large-image')
    })
  }
  showImage(event) {
    let element = $(event.srcElement)

    $("#shownImage").attr("src", element.data('large-image'))
    $('#lightbox_open').attr("href", element.data('huge-image'))
    $(".selected_galleryImage").removeClass("selected_galleryImage")
    element.addClass('selected_galleryImage')

    let titleString = element.data('title') + "<span id=\"galleryTitleDetails\">"

    if (element.data('medium')) {
      titleString += ", " + element.data('medium')
    }

    if (element.data('dimensions')) {
      titleString += ", " + element.data('dimensions')
    }

    titleString += "</span>"

    $('#galleryTitle').html(titleString)
    
    event.preventDefault()
// jQuery(".galleryImage").click(function () {
// var imageid = jQuery(this).attr('imageid');
// jQuery.getJSON("/artworks/get_image_data/" + imageid, function(data){
// jQuery("#shownImage").attr("src", data.large);
// jQuery('#lightbox_open').attr("href", data.huge);

// jQuery('a.lightbox').lightBox({
// imageBtnClose: '/images/close.gif',
// imageBtnPrev: '/images/prev.gif',
// imageBtnNext: '/images/next.gif',
// fixedNavigation: true
// });
  }
  
}
